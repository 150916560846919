<template>
    <transition name="slide" mode="out-in">
        <div class="register-success-tbf">
            <div class="success-modal">
                <div class="header-modal">
					<div class="icon"><icon-cart /></div>
                    <div class="title">{{ $t('register_success.title') }}</div>
                    <div class="description">{{ showInputEmails ? $t('register_success.description_with_emails') : $t('register_success.description') }}</div>
				</div>
                <div class="container-content-modal">
                    <div class="input-group emails-input" :class=" email_error == true ? 'has_error' : '' " v-if="showInputEmails">
                        <div class="main-input">
                            <input :disabled="keyEnter" type="email" :placeholder="$t('subscription.placeholder_emails')" class="input-text" v-model="inputEmail" @keydown.enter="saveEmailsNotifications(inputEmail, true)">
                            <div class="icon-right" v-if="inputEmail && inputEmail != ''" @click.stop="clearEmailInput"><icon-close class="icon-clear" /></div>
                            <button v-if="inputEmail" id="buttonAddEmails" class="save-button" @click="saveEmailsNotifications(inputEmail, false)">
						        <div class="loader-spin" v-if="loadingSubmit"></div>
                                <span class="text" v-else>{{$t('general.save')}}</span>
                            </button>
                        </div>
                        
                        <div class="selected-emails" v-if="savedEmails.length > 0">
                            <span v-for="email, index in savedEmails" class="name">
                                {{ email }}
                                <button class="remove" @click="deleteEmail(index)"><icon-close /></button>
                            </span>
                        </div>
                    </div>
                    <div v-if="email_error" class="email_error">{{$t('subscription.invalid_email')}}</div>
                </div>
                <div class="submit-form">
                    <button id="buttonSaveEmails" class="button-submit" @click="showInputEmails ? saveEmails() : redirect()">
						<div class="loader-spin" v-if="loadingSubmit"></div>
                        <div class="text" v-else>{{ $t('register_success.button-action') }}</div>
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import IconClose from '../Icons/Close'
	import IconCart from '../Icons/Cart'

    export default {
        components: {
            IconClose,
            IconCart
        },
    	data(){
    		return {
                inputEmail: '',
                email_error: false,
                savedEmails: [],
                keyEnter: false,
                showInputEmails: true,
				loadingSubmit: false
    		}
    	},
        mounted() {
            if(this.$cookies.get('firstRegistration')) {
                this.showInputEmails = true
            }

            this.savedEmails.push(this.$auth.user().email)
        },
    	methods: {
            redirect() {
                this.$router.push({name: 'dashboard'});
            },
            clearEmailInput(){
                this.inputEmail = '';
                this.email_error = false;
                $('.emails-invoices').removeClass('has_error');
            },
            saveEmailsNotifications(newEmail, bool){
                this.keyEnter = bool
                var buttonName = 'buttonAddEmails'
                var btnSubmit = document.getElementById(buttonName);

                var regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

                if(regexEmail.test(String(newEmail).toLowerCase())) {
                    this.email_error = false
                    $('.emails-invoices').removeClass('has_error')
                    this.savedEmails.push(newEmail.toLowerCase())
                    this.inputEmail = ''
                    this.keyEnter = false
                    btnSubmit.disabled = false
                } else {
                    this.email_error = true
                    $('.emails-invoices').addClass('has_error')
                    btnSubmit.disabled = false
                    this.keyEnter = false
                }
            },
            deleteEmail(index){
                this.savedEmails.splice(index,1);
            },
            saveEmails(){
                var buttonName = 'buttonSaveEmails'
                var btnSubmit = document.getElementById(buttonName);

                btnSubmit.disabled = true
				this.loadingSubmit = true

                if(this.savedEmails.length) {
                    axios.post('/sync-instance-notification', { email: this.savedEmails.join(',') })
                    this.$router.push({name: 'dashboard'})
                } else {
                    this.email_error = true
                    $('.emails-invoices').addClass('has_error')
                    btnSubmit.disabled = false
                    this.keyEnter = false
				    this.loadingSubmit = false
                }
            }
    	}
    }
</script>